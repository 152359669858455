import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import { Animated, LinkList } from 'components';

import content from './content';

const { title, p1, p2, links } = content;

const Appointments = () => (
  <>

    <Fade in timeout={1000}>
      <Typography variant='h1' color='textPrimary'>
        <Animated>{title}</Animated>
      </Typography>
    </Fade>


    <Typography style={{marginTop: '20px'}} variant='h3' color='textPrimary'>
      The length of sessions varies, although I generally work in hour sessions for individuals at £50 per hour, and I see couples for 75 minutes for £70.
    </Typography>

    <Typography style={{marginTop:'25px'}} variant='h3' color='textPrimary'>
      Location
    </Typography>

    <a href="https://goo.gl/maps/WDZJbjz9Ua7vQMkm6">
      <Typography>
        Trenpa Therapy Centre,
        3a Abbeydale Road South,
        Sheffield,
        S7 2QL
      </Typography>
    </a>




  </>
);

export default Appointments;
