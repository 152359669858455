import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import kt from '../../images/kt.jpg'

import { Animated } from 'components';

import content from './content';

const { tagline, title, subtitle } = content;

const Welcome = ({data}) => (
  <>
    <img style={{borderRadius:"100%", width:'30vw', maxWidth:'200px'}} src={kt}/>
    <Fade in timeout={1000}>
      <Typography variant='h1' color='textPrimary'>
        <Animated>{title}</Animated>
      </Typography>
    </Fade>
    <Typography variant='h3' color='textPrimary'>
      {subtitle}
    </Typography>
    <Typography variant='h5' color='textPrimary'>
      <a href="mailto:hello@katiewarren.co.uk">hello@katiewarren.co.uk</a>
    </Typography>
    <Typography variant='h5' color='textPrimary'>
      <a href="tel:07809 206348">07809 206348</a>
    </Typography>
  </>
);

export default Welcome;
