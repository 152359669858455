import { FaGithub } from 'react-icons/fa';

const content = {
  title:'The Therapy',
  h1: 'There are many reasons why people reach out to therapy.',
  p1: 'You may be finding things difficult right now, and struggling to understand overpowering thoughts, feelings and behaviours, but let me reassure you that you are not alone. Everyone goes through difficult periods in their life. However, it is how we managed these difficult times that can have such a significant impact on our Health and Wellbeing.',
  h2: 'No matter what your are going through right now, it can get better and things can change.',
  p2: 'Counselling and Psychotherapy can really support you through these difficult times. It provides you with a safe and warm environment to express yourself freely and explore difficult experiences. With the development of a greater understanding of self, you are able to challenge unhelpful, thoughts, feelings and behaviours, which may be holding you back. Its this process which makes change possible. As a therapist, I am here to support you in this, every step of the way.'

};

export default content;
