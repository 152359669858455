import { FaGithub } from 'react-icons/fa';

const content = {
  title: 'Appointments',
  p1: 'I have always been interested in how people think, feel and behave. I am a firm believer that we are all a product of our past experiences, both good and bad, and this impacts on how we are in the here and now, in both a positive and negative way. I studied Psychology at University to Masters level, before going on to become a qualified Life Coach and NLP Practitioner and then I completed my Diploma in Transactional Analysis. I have worked within the NHS and Charity sectors throughout my career, specifically within Mental Health, Substance Misuse and Domestic Violence.\n',
  p2: 'I set up my Private Practice in 2015, where I work with both individuals and couples who present with a variety of issues, including anxiety, depression, domestic violence, abuse, trauma, bereavement and relationship difficulties, to name just a few. Some clients know exactly what the ‘problem’ is and what they want out of the process and others aren\'t really that sure, and thats ok. As we can explore this throughout our time together.',

};

export default content;
