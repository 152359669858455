import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import { Animated, LinkList } from 'components';

import content from './content';

const { title, p1, p2,h1, h2, links } = content;

const Therapy = () => (
  <>
    <Fade in timeout={1000}>
      <Typography variant='h1' color='textPrimary'>
        <Animated>{title}</Animated>
      </Typography>
    </Fade>
    <Typography variant='h3' color='textPrimary'>
      {h1}
    </Typography>
    <Typography variant='p' color='textPrimary'>
      {p1}
    </Typography>
    <Typography style={{marginTop:'20px'}} variant='h3' color='textPrimary'>
      {h2}
    </Typography>
    <Typography variant='p' color='textPrimary'>
      {p2}
    </Typography>
  </>
);

export default Therapy;
